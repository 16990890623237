import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AboutBlock = () => {
  return (
    <Box my={5}>
      <Typography variant="h3" textAlign="center" mt={4}>
        About us
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "50vh",
              marginTop: 4,
              backgroundImage: "url(/coach.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={6} my={5}>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Typography variant="body2">
                We used to go by EDEN, but there was an upgrade, now we are
                i61groups (i61 for short). We still believe that the innate
                power of people for transformation is their potential. This
                power sadly sometimes lies untapped. Each of us is capable of
                far more than we currently perform at. By drawing into this
                immense reserve of dormant wisdom that lies beneath the surface,
                we activate our clients to increased levels of productivity and
                efficiency.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                Our clientele come from varying fields of sports, business,
                personal leadership, team leadership and executive leadership to
                even personal growth. Their testament is proof of the value
                required to achieve their goals.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                At i61 we firmly believe at you and your teams have all it takes
                to become exceptional and top performing. With insightful
                questions, unique and personal customized coaching approach, our
                team is pleased to be a part of the success stories of our
                customer. Having over 8 years of coaching experience, combined
                with over 3 years of leading agile transformation across a
                multi-national telecoms company, working with multiple teams
                aiming for multiple deadlines, you can be guaranteed the
                best-in-class service, and results within a few meetings.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutBlock;
