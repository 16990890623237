import React from "react";
import Showcase from "../../components/showcase/Showcase";
import Feature from "../../components/features/Feature";
import Testimonals from "../../components/testimonials/Testimonals";

const Home = () => {
  return (
    <div>
      <Showcase />
      <Feature />
      <Testimonals />
    </div>
  );
};

export default Home;
