import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Link, NavLink } from "react-router-dom";
import "./Navigation.css";

const Navigation = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <AppBar
        position="static"
        sx={{ backgroundColor: "white", color: "rgb(45, 55, 72)" }}
      >
        <Container>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src="logo.png"
                alt="main logo"
                style={{ width: "100px", height: "50px" }}
              />
            </Link>

            <List
              sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
            >
              <NavLink
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
                activeClassName="active-link"
              >
                <ListItem>
                  <ListItemText primary="Home" />
                </ListItem>
              </NavLink>
              <NavLink
                to="/about"
                style={{ textDecoration: "none", color: "inherit" }}
                activeClassName="active-link"
              >
                <ListItem>
                  <ListItemText primary="About" />
                </ListItem>
              </NavLink>
              <NavLink
                to="/contact"
                style={{ textDecoration: "none", color: "inherit" }}
                activeClassName="active-link"
              >
                <ListItem>
                  <ListItemText primary="Contact" />
                </ListItem>
              </NavLink>
            </List>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navigation;
