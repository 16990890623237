import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Service from "./../service/Service";

const Feature = () => {
  return (
    <Box my={5}>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            textAlign: "center",
            gap: 2,
          }}
        >
          <Typography variant="h4">Our Offerings</Typography>
          <Service />
          <Typography variant="h6">
            Tailored to the needs of your company
          </Typography>
        </Box>
        <Box
          sx={{
            height: "50vh",
            marginTop: 4,
            backgroundImage: "url(/coach.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
      </Container>
    </Box>
  );
};

export default Feature;
