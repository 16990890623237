import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import React, { useState } from "react";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = () => {
    console.log(`Subscribed with email: ${email}`);
  };

  return (
    <footer
      style={{ backgroundColor: "#28282B", padding: 20, color: "#CCCCCC" }}
    >
      <Container>
        <Grid container spacing={0} my={4}>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LocationOnIcon />
            <Box sx={{ marginLeft: 1 }}>
              <Typography variant="h6" color="#fff">
                Find us
              </Typography>
              <Typography variant="body2">Sole 76, Tallinn Estonia</Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PhoneIcon />
            <Box sx={{ marginLeft: 1 }}>
              <Typography variant="h6" color="#fff">
                Call us
              </Typography>
              <Typography variant="body2">+372 5391 2520</Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <EmailIcon />
            <Box sx={{ marginLeft: 1 }}>
              <Typography variant="h6" color="#fff">
                Mail us
              </Typography>
              <Typography variant="body2">info@i61groups.com</Typography>
            </Box>
          </Grid>
        </Grid>
        <hr style={{ margin: [2, 0] }} />
        <Box my={3}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12} gap={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  marginBottom: 1,
                }}
              >
                <img
                  src="logo2.png"
                  alt="main2 logo"
                  style={{ width: "100px", height: "50px" }}
                />
              </Typography>
              <Typography variant="body2">
                We used to go by EDEN, but there was an upgrade, now we are
                i61groups (i61 for short). We still believe that the innate
                power of people for transformation is their potential.
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="h6" color="#fff">
                Useful Links
              </Typography>
              <Grid container spacing={4}>
                <Grid item>
                  <List>
                    <ListItem>
                      <ListItemText primary="About Us" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Our Services" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Blog" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Contact Us" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item>
                  <List>
                    <ListItem>
                      <ListItemText primary="Help Center" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="FAQs" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Privacy Policy" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Terms of Service" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="h6" color="#fff">
                Subscribe
              </Typography>
              <Typography variant="body2">
                Don't miss to subscribe to our new feeds, kindly fill the form
                below
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <TextField
                  label="Email"
                  variant="filled"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    marginBottom: 2,
                    width: "300px",
                    height: "56px",
                    borderRadius: 0,
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubscribe}
                  sx={{
                    marginBottom: 2,
                    width: "120px",
                    height: "56px",
                    borderRadius: 0,
                  }}
                >
                  <SendIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
