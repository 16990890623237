import React, { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const form = useRef();
  const emailServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const emailTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailUserId = process.env.REACT_APP_EMAILJS_USER_ID;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(emailServiceId, emailTemplateId, form.current, emailUserId)
      .then(
        (result) => {
          toast.success("Email sent successfully!");
          e.target.reset();
        },
        (error) => {
          toast.error("Error sending email. Please try again.");
        }
      );
  };

  return (
    <Box mb={5}>
      <Container>
        <Grid container spacing={5}>
          <Grid
            item
            sx={{ display: { xs: "none", md: "block" } }}
            md={6}
            gap={5}
          >
            <Grid container spacing={5}>
              <Grid item md={12}>
                <Card sx={{ Width: 200 }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PhoneIcon
                      style={{
                        fontSize: 40,
                        color: "#2196F3",
                      }}
                    />
                    <Box ml={3}>
                      <Typography variant="h6">Call us directly at</Typography>
                      <Typography variant="h4">+372 5391 2520</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12}>
                <Card sx={{ Width: 200 }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EmailIcon
                      style={{
                        fontSize: 40,
                        color: "#2196F3",
                      }}
                    />
                    <Box ml={3}>
                      <Typography variant="h6">Electronic Mail</Typography>
                      <Typography variant="h4">info@i61groups.com</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ Width: 200 }}>
              <CardContent>
                <Box component="form" ref={form} onSubmit={sendEmail}>
                  <Typography variant="h5" textAlign="center" my={2}>
                    Get in Touch
                  </Typography>
                  <Grid container spacing={5}>
                    <Grid item md={12} sx={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        label="Name *"
                        type="text"
                        sx={{ width: "100%" }}
                        name="from_name"
                      />
                    </Grid>
                    <Grid item md={12} sx={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        label="Email *"
                        type="email"
                        name="reply_to"
                      />
                    </Grid>
                    <Grid item md={12} sx={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        label="Subject *"
                        type="test"
                        name="user_subject"
                      />
                    </Grid>
                    <Grid item md={12} sx={{ width: "100%" }}>
                      <TextField
                        label="Message"
                        multiline
                        rows={5}
                        variant="outlined"
                        fullWidth
                        name="message" // Add name attribute for emailjs
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        sx={{ width: "120px" }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default ContactForm;
