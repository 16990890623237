import { Box, Container, Typography } from "@mui/material";
import React from "react";

const ContactHeader = () => {
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 4,
          }}
        >
          <Typography variant="h2">Contact us</Typography>
          <Typography variant="body1" width="70%" textAlign="center">
            Have questions or need assistance? Feel free to reach out to us. We
            value your feedback and are here to help. You can contact us via the
            information below:
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default ContactHeader;
