import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const Showcase = () => {
  const navigate = useNavigate();

  const containerStyle = {
    padding: "40px 0",
    height: "100%",
  };

  const gridItemStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  };

  return (
    <Box
      sx={{ backgroundColor: "#C0C0C0", height: { xs: "60vh", md: "70vh" } }}
    >
      <Container sx={{ ...containerStyle, height: "100%" }}>
        <Grid container spacing={0} style={{ height: "100%" }}>
          <Grid item xs={12} md={6} sx={gridItemStyle}>
            <Typography
              variant="2"
              sx={{
                fontSize: { xs: "2rem", sm: "3rem", md: "3rem" },
                textAlign: { xs: "center" },
              }}
            >
              Coach. Facilitate. Train
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
                textAlign: { xs: "center" },
              }}
            >
              i61groups aims to inspire, coach and facilitate leaders and
              professionals to their highest levels of potential fulfilment.
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                display: { xs: "flex" },
                justifyContent: { xs: "center" },
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate("/about")}
              >
                About us
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginLeft: "10px" }}
                onClick={() => navigate("/contact")}
              >
                Get in Touch
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            sx={{ ...gridItemStyle, display: { xs: "none", md: "block" } }}
            md={6}
          >
            <img
              src="sunrise.png"
              alt="Your Image"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Showcase;
