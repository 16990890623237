import { Container } from "@mui/material";
import React from "react";
import AboutBlock from "../../components/about-block/AboutBlock";

const About = () => {
  return (
    <div>
      <Container>
        <AboutBlock />
      </Container>
    </div>
  );
};

export default About;
