import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

const data = [
  {
    icon: (
      <svg
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        ></path>
      </svg>
    ),
    title: "Coaching",
    description:
      "Through empathy, attentive leadership, and experience, we unleash team and leader potential for improved collaboration, effectiveness, and execution.",
  },
  {
    icon: (
      <svg
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        ></path>
      </svg>
    ),
    title: "Facilitation",
    description:
      "Our range of expertise spans guided facilitation for casual events, corporate events, webinars, seminars and conferences.",
  },
  {
    icon: (
      <svg
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        ></path>
      </svg>
    ),
    title: "Trainings",
    description:
      "When it comes to group trainings and workshops, you can count on us to deliver a unique and custom built programs that cater to your organizational goals.",
  },
];

const Service = () => {
  return (
    <Box marginBlock={2}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          {data.map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 3,
                }}
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                >
                  {item.icon}
                </Avatar>
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  sx={{ mt: 2 }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body1" align="center">
                  {item.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Service;
