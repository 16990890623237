import React from "react";
import ContactHeader from "../../components/contact-header/ContactHeader";
import ContactForm from "../../components/contact-form/ContactForm";

const Contact = () => {
  return (
    <>
      <ContactHeader />
      <ContactForm />
    </>
  );
};

export default Contact;
