import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";

const testimonialsData = [
  {
    content:
      "i61's coaching, guided my academic success, fostered creativity, and transformed my work routine and perspective.",
    customerName: "Taavi Salum, Electronics Engineer at Ericsson",
  },
  {
    content:
      "I am so happy that I achieved a long goal of shedding 20Kilos within a year after engaging the coaching sessions. I am excited to have more sessions this year.",
    customerName: "Eberechukwu Atie, CEO Ebby Crunchy Ginger Flakes",
  },
  {
    content:
      "The coaching program exceeded my expectations. It played a pivotal role in refining my leadership skills. Highly recommended!",
    customerName: "Ousmane Ndure, CEO Futpal",
  },
];

const Testimonals = () => {
  const navigate = useNavigate();
  return (
    <Box my={5}>
      <Container>
        <Typography variant="h4" gutterBottom textAlign="center">
          Here's what our clients say
        </Typography>
        <Grid container spacing={2}>
          {testimonialsData.map((testimonial, index) => (
            <Grid key={index} item xs={12} md={4}>
              <Card
                sx={{
                  backgroundColor: "rgba(21, 101, 192, 0.5)",
                  color: "#fff",
                }}
              >
                <CardContent>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    <blockquote>
                      <FormatQuoteIcon />
                      {testimonial.content}
                    </blockquote>
                    <p>- {testimonial.customerName}</p>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
          <Typography variant="h5" sx={{ padding: 2 }}>
            Unlock your optimal potential; there's always room for improvement
            on your path to excellence.
          </Typography>
          <Button variant="contained" onClick={() => navigate("/contact")}>
            Get in Touch <ArrowRightAltIcon />
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonals;
